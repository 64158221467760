<template>
  <div class="root">
    <div class="mask">
      <!-- <el-button @click="$router.push('/index')" class="toIndex" type="text" icon="el-icon-back"
        ></el-button
      > -->
      <el-image
          @click.native="$router.push('/index')"
          fit="cover"
          :src="logoUrl"
      ></el-image>
      <div class="hint">
        <h3>温馨提示：</h3>
        <p>
          1. 学生用户登录账号都为手机号，上学期已经有账号的用户输入原账号和密码即可登录。
        </p>
        <p>
          2. 若学生用户没有账号，请点击立即注册按钮，输入新的手机号注册作为学生的登录账号，同时输入学生的身份号进行身份认证。确认填写无误后，根据操作提示完成相应的注册流程操作。
        </p>
        <p>
          3. 若身份证号没有绑定学生学籍信息（武侯区内学生，升学转学未更新学生学籍信息），请学生行填写个人信息（年级）进行注册。
        </p>
        <p>4. 根据管理要求一个孩子，一个手机号只能对应一个孩子，如果您不止有一个孩子，请使用不同的手机号进行注册。</p>
<!--        <p>5. 若有其他登录问题，请咨询技术支持QQ:312414916</p>-->
        <p>5. 若有其他登录问题，请在武侯乐学通公众号留言。</p>
        <!-- <p>
					三顾云：<a href="https://new.whqjyy.com" target="__brank"
						>https://new.whqjyy.com</a
					>
				</p> -->
      </div>
      <el-card>
        <h3>
          <span
              @click="
							loginMethod =
								loginMethod === 'code' ? 'code' : 'account'
						"
              :class="loginMethod !== 'wx' ? 'active' : ''"
          >账户登录</span>
          <!-- <span
						@click="getLoginWeChatCode"
						:class="loginMethod === 'wx' ? 'active' : ''"
						>微信登录</span
					> -->
        </h3>
        <div
            v-if="loginMethod === 'account'"
            class="account-box"
        >
          <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="70px"
          >
            <el-form-item
                label="用户名"
                prop="username"
            >
              <el-input
                  placeholder="用户名/手机号"
                  prefix-icon="el-icon-user"
                  size="small"
                  v-model="ruleForm.username"
              >
              </el-input>
            </el-form-item>
            <el-form-item
                label="密码"
                prop="password"
            >
              <el-input
                  size="small"
                  v-model="ruleForm.password"
                  prefix-icon="el-icon-key"
                  :type="flag ? 'password' : 'text'"
                  placeholder="请输入密码"
              >
                <i
                    slot="suffix"
                    :class="
										flag ? 'el-icon-minus' : 'el-icon-view'
									"
                    style="margin-top: 8px; font-size: 18px"
                    autocomplete="auto"
                    @click="flag = !flag"
                />
              </el-input>
            </el-form-item>
            <el-form-item
                label="验证码"
                prop="captcha"
            >
              <el-input
                  style="width: 130px"
                  size="small"
                  v-model="ruleForm.captcha"
                  prefix-icon="el-icon-unlock"
                  placeholder="请输入验证码"
                  @keyup.native.enter="submit('ruleForm')"
              >
              </el-input>
              <img
                  @click="getCaptcha"
                  class="captcha"
                  :src="captcha.image"
              />
            </el-form-item>
          </el-form>
          <p class="forget-nav">
            <!-- <span @click="loginMethod = 'code'">验证码登录</span> -->
            <span @click="handleResetPwd">忘记密码?</span>
            <span @click="$router.push(`/register`)">还没账号？立即注册</span>
          </p>
          <button
              @click="submit('ruleForm')"
              class="btn"
          >
            登录
          </button>
        </div>
        <!-- <div v-if="loginMethod === 'code'" class="account-box code-box">
          <el-input
            placeholder="请输入手机号"
            prefix-icon="el-icon-user"
            size="small"
            v-model="username"
          >
          </el-input>

          <div class="getcode">
            <el-input
              placeholder="请输入验证码"
              prefix-icon="el-icon-connection"
              size="small"
              v-model="password"
            >
            </el-input>
            <button class="btn">获取验证码</button>
          </div>
          <p class="forget-nav">
            <span @click="loginMethod = 'account'">密码登录</span>
          </p>
          <button class="btn">登录</button>
        </div> -->
        <div
            v-else-if="loginMethod === 'wx'"
            class="wx-box"
        >
          <!--  <empty-box>该功能暂未开放，敬请期待</empty-box>-->
          <div>
            <img
                :src="qrcode"
                alt=""
            />
          </div>
          <div class="sao">打开微信扫一扫即可登录</div>
        </div>
      </el-card>
    </div>
    <!-- 忘记密码弹窗 -->
    <el-dialog
        title="设置新密码"
        class="resetPwd"
        :visible.sync="dialogFormVisible"
    >
      <el-form
          ref="rePwdForm"
          :rules="resetPwdRules"
          :model="resetPwd"
      >
        <el-form-item
            prop="account"
            label="账号"
            label-width="90px"
        >
          <el-input
              style="width: calc(100% - 10px)"
              size="mini"
              v-model="resetPwd.account"
              autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
            prop="value"
            label="验证码"
            label-width="90px"
        >
          <el-input
              style="width: calc(100% - 120px)"
              size="mini"
              v-model="resetPwd.value"
              autocomplete="off"
          ></el-input>
          <el-button
              @click="getCode"
              style="margin-left: 10px; width: 95px"
              size="mini"
              :type="this.getCodeTime > 0 ? 'info' : 'primary'"
          >{{
              getCodeTime > 0 ? getCodeTime : '获取验证码'
            }}
          </el-button>
        </el-form-item>
        <el-form-item
            prop="newPassword"
            label="新密码"
            label-width="90px"
        >
          <el-input
              style="width: calc(100% - 10px)"
              size="mini"
              v-model="resetPwd.newPassword"
              type="password"
              autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item
            prop="newPassword1"
            label="重复密码"
            label-width="90px"
        >
          <el-input
              style="width: calc(100% - 10px)"
              size="mini"
              v-model="resetPwd.newPassword1"
              type="password"
              autocomplete="off"
              @keyup.native.enter="okResetPwd"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
          slot="footer"
          class="dialog-footer"
      >
        <el-button
            size="small"
            @click="dialogFormVisible = false"
        >取 消
        </el-button>
        <el-button
            size="small"
            type="primary"
            @click="okResetPwd"
        >确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 查看帮助弹窗 -->
    <el-dialog
        title="常见账号异常问题"
        :visible.sync="dialogHelp"
    >
      <div class="help-content">
        <p>
          1.登录三顾云平台:<a
            href="https://new.whqjyy.com"
            target="__brank"
        >https://new.whqjyy.com</a>，点击右上角头像进入个人中心。执行一次修改一卡通手机号操作，再输入一次手机号点击确定保存，同时查看三顾云信息里，孩子的性别是否勾选！该一卡通手机号作为乐学通登录账号，初始密码：123456
        </p>
        <div><img
            src="../../assets/img/accHelp1.png"
            alt=""
        /></div>
        <p>
          2.若一开始家里有两个及以上的小孩填写一卡通手机号时使用了同一手机号，乐学通会随机默认将一卡通手机号手机号绑定给一个孩子作为账号，使用这个手机号登录乐学通便可以查看到绑定了哪个孩子，登录三顾云修改其余的孩子一卡通手机号即可完成乐学通平台账号的生成。
        </p>
        <p>
          3.如果都没有绑定孩子的信息即无法登录，修改—保存下每个孩子的一卡通手机号即可生成对应的乐学通账号。
        </p>
        <p>4.一卡通手机号修改后，将会更改乐学通账号信息。</p>
        <p>5.一卡通手机号作为接受短信通知，请核实正确填写。</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import md5 from "js-md5";
import logo from "@/assets/img/logo2.svg";
import {
  getCaptcha_api,
  loginByUsername_api,
  getAccInfo_api,
  getPhoneCode_api,
  updateUserPassWordByPhone_api,
  getLoginWeChatCode_api,
  weChatCheckLogin_api,
} from "@/api/user.js";
import {sessionSet, set, get} from "@/utils/local.js";
import emptyBox from "@/components/empty.vue";

export default {
  inject: ["reload"],
  components: {emptyBox},
  data() {
    var validateNewPassword1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.resetPwd.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      studentId: "",
      logoUrl: logo,
      loginMethod: "account", // 登录方式
      isDisabled: false, // 是否禁用登录
      flag: true, // 是否密码框
      qrcode: "", // 微信登录二维码
      sceneStr: "", // 微信登录场景标识
      sceneTimer: "", //场景定时器
      sceneTimerTimeCode: "", //场景定时器强制销毁时效数
      captcha: {},
      ruleForm: {
        // username: "13612121212", // 用户名或手机号
        // username: "11", // 用户名或手机号 // 学生
        username: "", // 用户名或手机号
        password: "", // 密码或者验证码
        captcha: "", // 验证码
        // username: "hyg0696", // 用户名或手机号
        // username: "dy8192", // 用户名或手机号
        // password: "123456", // 密码或者验证码
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名/手机号",
            trigger: "blur",
          },
        ],
        password: [{required: true, message: "请输入密码", trigger: "blur"}],
        captcha: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },

      dialogFormVisible: false, // 忘记密码的弹窗
      dialogHelp: false, // 查看帮助弹窗
      resetPwd: {
        tenantId: this.$tenantId,
        account: "",
        value: "",
        newPassword: "",
        newPassword1: "",
        id: "",
      },
      accountPre: "",
      resetPwdRules: {
        account: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
        value: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
        ],
        newPassword1: [
          {
            required: true,
            message: "请再次输入密码",
            trigger: "blur",
          },
          {validator: validateNewPassword1, trigger: "blur"},
        ],
      },
      getCodeTime: 0,
      timer: null,
    };
  },
  mounted() {
    this.timer = setTimeout(this.weChatCheckLogin_api, 1000);

    // 第三方登录
    const loginMode = window.localStorage.getItem("transfer");
    if (loginMode) {
      const userInfosan = JSON.parse(
          window.localStorage.getItem("userInfosan")
      );

      this.transferLogin(userInfosan);
    }
  },
  methods: {
    submit(formName) {
      if (this.isDisabled) return this.$message.error("请勿频繁操作");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isDisabled = true;
          setTimeout(() => {
            this.isDisabled = false;
          }, 4000);
          this.getCaptcha();
          this.loginByUsername();
        }
      });
    },
    // 找回密码
    handleResetPwd() {
      this.dialogFormVisible = true;
      this.resetPwd = {
        tenantId: this.$tenantId,
        account: "",
        value: "",
        newPassword: "",
        newPassword1: "",
        id: "",
      };
      this.accountPre = "";
    },
    // 获取手机验证码
    async getCode() {
      if (this.getCodeTime > 0)
        return this.$message.info("请" + this.getCodeTime + "秒后再次获取");
      if (!this.resetPwd.account.trim())
        return this.$message.error("请输入账号");
      let {code, data, msg} = await getPhoneCode_api({
        code: "ali-validate",
        account: this.resetPwd.account,
        tenantId: this.$tenantId,
      });
      if (code == 200) {
        this.accountPre = this.resetPwd.account;
        this.getCodeTime = 60;
        this.timer = setInterval(() => {
          if (this.getCodeTime <= 0) {
            clearInterval(this.timer);
          } else {
            this.getCodeTime--;
          }
        }, 1000);
        this.$message({
          type: "success",
          message: "验证码已发送，请注意查收",
        });
        this.resetPwd.id = data.id;
      } else {
        this.$message.error(msg);
      }
    },
    // 确认找回
    okResetPwd() {
      this.$refs.rePwdForm.validate(async (valid) => {
        if (valid) {
          if (!this.accountPre) {
            return this.$message.error("请先获取验证码");
          }
          if (this.accountPre != this.resetPwd.account) {
            return this.$message.error(
                "获取验证码的账号和重置密码的账号不一致"
            );
          }
          let {code, msg} = await updateUserPassWordByPhone_api({
            tenantId: this.$tenantId,
            account: this.resetPwd.account,
            value: this.resetPwd.value,
            newPassword: md5(this.resetPwd.newPassword),
            newPassword1: md5(this.resetPwd.newPassword1),
            id: this.resetPwd.id,
          });
          if (code == 200) {
            this.$message({
              type: "success",
              message: msg,
            });
            this.dialogFormVisible = false;
          } else {
            this.$message.error(msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 获取微信登录二维码
    async getLoginWeChatCode() {
      let {code, data} = await getLoginWeChatCode_api({
        tenantId: this.$tenantId,
      });
      if (code == 200) {
        this.sceneTimerTimeCode = 25;
        this.qrcode = data.ticket;
        this.sceneStr = data.sceneStr;
        this.loginMethod = "wx";
        this.sceneTimer = setInterval(() => {
          this.weChatCheckLogin(this.sceneStr);
        }, 3000);
      } else {
      }
      this.loginMethod = "wx";
    },
    async weChatCheckLogin(sceneKey) {
      this.sceneTimerTimeCode--;
      //强制销毁
      // console.log("caode1="+this.sceneTimerTimeCode)
      if (this.sceneTimerTimeCode <= 0) {
        //console.log("caode2="+this.sceneTimerTimeCode)
        clearInterval(this.sceneTimer);
      }

      let {data} = await weChatCheckLogin_api({
        sceneStr: sceneKey,
      });
      if (data === "false") {
        //销毁
        clearInterval(this.sceneTimer);
        //账号未绑定微信
        this.$message({
          type: "success",
          message: "未绑定账号信息，请先登录账号绑定！！！",
        });
        this.reload();
      }
      if (data.length > 10) {
        //销毁
        clearInterval(this.sceneTimer);
        //默认调用登录接口，完成跳转
        this.loginByWeChat(data);
      }
    },
    async loginByWeChat(data) {
      let res = await loginByUsername_api({
        tenantId: this.$tenantId,
        grant_type: "wechatapp",
        scope: "all",
        uniAppKey: "keyo32tCZcx2mWse0UG67cbSkRWcipKyZfQ",
        openId: data,
        type: "account",
      });
      // console.log("LOGIN: ", res);
      if (res.error) {
        return this.$message.error(res.error_description);
      }
      let token = {
        access_token: res.access_token,
        refresh_token: res.refresh_token,
        token_type: res.token_type,
      };
      sessionSet("tk", token);
      sessionSet("userInfo", res);
      sessionSet("userId", res.user_id);
      this.$store.commit("updateLogin", true);
      this.$store.commit("updateUserInfo", res);
      this.getAccInfo(res.user_id); // 跳转
    },
    // 获取动态验证码
    async getCaptcha() {
      let res = await getCaptcha_api();
      this.captcha = res;
    },
    // 用户名登录
    async loginByUsername() {
      let res = await loginByUsername_api({
        tenantId: this.$tenantId,
        username: this.ruleForm.username,
        password: md5(this.ruleForm.password),
        "Captcha-Key": this.captcha.key,
        "Captcha-Code": this.ruleForm.captcha,
        // grant_type: "password",
        grant_type: "captcha",
        scope: "all",
        type: "account",
      });
      if (res.error) {
        return this.$message.error(res.error_description);
      }
      let token = {
        access_token: res.access_token,
        refresh_token: res.refresh_token,
        token_type: res.token_type,
      };
      sessionSet("tk", token);
      sessionSet("userInfo", res);
      sessionSet("userId", res.user_id);
      this.$store.commit("updateLogin", true);
      this.$store.commit("updateUserInfo", res);
      this.getAccInfo(res.user_id); // 跳转
    },

    // 第三方登录
    async transferLogin(userInfosan) {
      // console.log('--------------', userInfosan)
      let res = await loginByUsername_api({
        tenantId: userInfosan.tenantId,
        "Captcha-Code": "0000",
        username: userInfosan.account,
        password: userInfosan.password,
        grant_type: "captcha",
        scope: "all",
        type: "account",
        logType: 1,
      });
      // console.log('+++++++++++++=', res)
      if (res.error) {
        return this.$message.error(res.error_description);
      }
      let token = {
        access_token: res.access_token,
        refresh_token: res.refresh_token,
        token_type: res.token_type,
      };
      sessionSet("tk", token);
      sessionSet("userInfo", res);
      sessionSet("userId", res.user_id);
      this.$store.commit("updateLogin", true);
      this.$store.commit("updateUserInfo", res);
      this.getAccInfo(res.user_id); // 跳转
    },

    // 跳转信息
    async getAccInfo(userId) {
      let {code, data} = await getAccInfo_api({
        userId,
      });
      if (code === 200) {
        this.$store.commit("updateUserInfo", data);
        // 获取地址栏重定向参数
        var redirect = this.$route.query.redirect || "/index/index";
        if (
            redirect.includes("/scene/sceneAdd") &&
            (data.roleName === "学生" || data.roleName === "student")
        ) {
          // 如果是去场景预约界面并且是学生登录
          this.$confirm(
              "检测到你的帐号为学生！团队只支持教师帐号预约！你可以去看看有哪些课程可以预约?",
              "提示",
              {
                confirmButtonText: "去看看课程",
                cancelButtonText: "返回场景",
                type: "warning",
              }
          )
              .then(() => {
                this.$router.replace("/course");
              })
              .catch(() => {
                this.$router.replace("/scene");
              });
        } else this.$router.replace(redirect);
        this.$message({
          type: "success",
          message: "登录成功",
        });
        this.$store.commit("refresh_token", true);
      }
    },
  },
  created() {
    this.getCaptcha();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.sceneTimer);
  },
};
</script>

<style lang="less" scoped>
@title-color: #0079fe;
.root {
  height: 100%;

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("../../assets/img/loginBackground.png");
    background-size: 100% 100%;
    // .toIndex {
    //   position: absolute;
    //   top: 0px;
    //   left: 10px;
    //   color:#fff;
    //   z-index: 999;
    //   font-size:1.5em;
    //   &:hover{
    //     color:@title-color;
    //   }
    // }
    .el-image {
      cursor: pointer;
      width: 500px;
      height: 150px;
      position: absolute;
      top: 100px;
      left: 150px;
    }

    .hint {
      position: absolute;
      top: 50%;
      left: 200px;
      transform: translateY(-40%);
      font-size: 14px;
      width: 300px;
      line-height: 1.8em;

      p {
        text-indent: 2em;
      }
    }

    .el-card {
      width: 350px;
      position: absolute;
      top: 50%;
      left: 540px;
      transform: translateY(-40%);
      font-size: 14px;
      padding: 10px;

      h3 {
        display: flex;
        justify-content: space-around;
        margin-bottom: 50px;

        span {
          color: #999;
          cursor: pointer;
          user-select: none;

          &.active {
            color: #444;
            border-bottom: 2px solid @title-color;
            padding-bottom: 4px;
          }
        }
      }

      .account-box {
        /deep/ .el-form-item__content {
          height: 40px;
          line-height: 32px;

          .captcha {
            cursor: pointer;
            width: 100px;
            height: 35px;
            position: absolute;
            top: -2px;
            left: 140px;
          }
        }

        .el-input {
          margin-bottom: 20px;

          /deep/ .el-input__inner {
            border-radius: 20px;
          }
        }

        .forget-nav {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          padding: 0 10px;

          span {
            user-select: none;
            color: #aaa;
            cursor: pointer;
            font-size: 12px;

            &:hover {
              color: @title-color;
            }

            &:last-child {
              color: #f00;
            }
          }
        }

        .btn {
          width: calc(100% - 100px);
          margin-left: 50px;
          height: 30px;
          background-color: @title-color;
          border-radius: 15px;
          letter-spacing: 30px;
          text-indent: 30px;
        }
      }

      // .code-box {
      //   .getcode {
      //     display: flex;
      //     .btn {
      //       width: 150px;
      //       margin-left: 10px;
      //     }
      //   }
      // }
      .wx-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          height: 180px;
          width: 180px;
        }

        .sao {
          margin-top: 15px;
          color: #888;
        }
      }
    }
  }

  .resetPwd {
    & /deep/ .el-dialog {
      width: 400px;

      .el-dialog__body {
        padding: 10px 20px 0;
      }
    }
  }

  .help-content {
    padding: 0 20px;

    p {
      letter-spacing: 1.5px;
      line-height: 1.5em;
    }

    div {
      text-align: center;

      img {
        width: 60%;
      }
    }
  }
}
</style>
